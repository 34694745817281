export default function NothingToShowBloc(props){
    return(
        <div className='mt-3' style={ { display:"flex", height:"180px", justifyContent:'center', alignItems:'center', border:'1px solid #dedede', borderRadius:'9px',backgroundSize:'cover', backgroundPosition:'center' } }  >
            <div className="w-75">
                <p className='text-muted text-center'>
                    Wait for coach abdou to assign you a diet and a training plans.<br/> Stay tuned.
                </p>
                
            </div>
        </div>
    );
}